import "./App.css";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    const redirectToWebsite = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const location = data.country_name;

        if (location === 'United States') {
          window.location.href = 'https://us.collegebuy.net/';
        } else {
          window.location.href = 'https://en.collegebuy.net/';
        }
      } catch (error) {
        console.log('Error:', error);
      }
    };

    redirectToWebsite();
  }, []);

  return (
    <div className="loader_body">
      <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    </div>
  );
}

export default App;
